import React, { useMemo, useState } from 'react';
import Navbar from '../navbar';
import { useUserStore } from '../../storesZustand/useUserStore';

const Sidebar = () => {
  const [user] = useUserStore((state) => [state.user]);
  const role = user?.role;
  
  const navItems = useMemo(() => {
    const items = [];
    if (role === 'superadmin') {
      items.push(
        {
          title: 'Dashboard',
          icon: '/images/dashboard-new.svg',
          path: user.organization_id === 1 ? '/dashboard/organizations' : '/dashboard/locations',
          pathActive: 'dashboard'
        },
        {
          title: 'Employees',
          icon: '/images/admin-symbol.svg',
          path: `/training`,
          pathActive: 'training'
        }
      );
      if (user?.canSeeEverything) {
        items.push({
          title: 'Devices',
          icon: '/images/device.svg',
          path: '/devices',
          pathActive: 'devices'
        });
      }
    } else if (role === 'admin') {
      items.push(
        {
          title: 'Dashboard',
          icon: '/images/dashboard-new.svg',
          path: '/dashboard/locations',
          pathActive: 'dashboard'
        },
        {
          title: 'Employees',
          icon: '/images/admin-symbol.svg',
          path: `/training/org/${user.organization_id}`,
          pathActive: 'training'
        }
      );
    }
    
    items.push({
      title: 'Training',
      icon: '/images/clipboard-text.svg',
      path: '/training-portal',
      pathActive: 'training-portal'
    });
    
    if (user?.canSeeEverything) {
      items.push({
        title: 'Settings',
        icon: '/images/Frame (8).svg',
        path: '/settings',
        pathActive: 'settings'
        
      });
    }
    
    return items;
  }, [role]);
  
  return (
    <React.Fragment>
      <div
        className='md:flex flex-col md:flex-row md:min-h-screen bg-white  w-[240px] z-50 fixed'
        style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px -2px 4px' }}
      >
        <div className='flex flex-col  w-[160px] mx-auto   text-gray-700  flex-shrink-0'>
          <div className=''>
            <Navbar navItems={navItems} user={user} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
